import React from "react";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import * as paginationStyles from "./pagination.module.css";
import classNames from "classnames/bind";

const Pagination = ({ perPage, totalCount, currentPage, base, threshold = 2 }) => {
    // Threshold represents how many page-numbers will appear away from the page the user is on.
    // For instance, on page 5, if the threshold is 2, then pages 3, 4, 5, 6 and 7 will be shown in the pagination.

    // Styles
    const cx = classNames.bind(paginationStyles);

    // Numeric value of total number of pages in the pagination
    const totalPages = Math.ceil(totalCount / perPage);

    // Numeric value of what page is previous, and what page is next
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;

    // Truthy value for if there is a next page, or a previous page
    const hasNextPage = nextPage <= totalPages;
    const hasPrevPage = prevPage >= 1;

    // Used when generated page numbers are far enough from total page count
    let ellipsis = false;

    // Link component with a few pagination-specific conditions
    const PaginatedLink = ({ base, pgNum, currentPage }) => {
        // Generate class for when the Link is the currently active page
        const isActive = pgNum + 1 === currentPage ? "isActive" : false;

        // Condiion to remove href ("to" attribute) when the Link is the currently active page
        const to = !isActive ? `${base}${pgNum > 0 ? pgNum + 1 : ""}` : false;

        return (
            <Link className={cx("pageNumber", isActive)} key={pgNum} to={to}>
                {pgNum + 1}
            </Link>
        );
    };

    return (
        <nav className={cx("paginationWrapper")}>
            <ul className={cx("paginationList")}>
                <li>
                    <Link className={cx("firstPage")} to={hasPrevPage && `${base}`}>
                        &laquo; <span className={cx("textLabel")}>First</span>
                    </Link>
                </li>
                <li>
                    <Link
                        className={cx("prevNext", "prev")}
                        to={hasPrevPage && `${base}${prevPage > 1 ? prevPage : ""}`}
                    >
                        &lsaquo; <span className={cx("textLabel")}>Previous</span>
                    </Link>
                </li>
                {Array.from({ length: totalPages }).map((el, pgNum) => {
                    // Conditions for generating the page number
                    if (
                        pgNum + 1 >= currentPage - threshold &&
                        pgNum < currentPage + threshold &&
                        pgNum < totalPages - threshold
                    ) {
                        return (
                            <li key={pgNum}>
                                <PaginatedLink base={base} pgNum={pgNum} currentPage={currentPage} />
                            </li>
                        );
                    }
                    // Determine if an ellipsis is needed
                    if (currentPage < totalPages - threshold - 2) {
                        ellipsis = (
                            <li>
                                <span>&hellip;</span>
                            </li>
                        );
                    }
                    return null;
                })}
                {ellipsis}
                {Array.from({ length: totalPages }).map((el, pgNum) => {
                    // Last two items in the pagination
                    if (pgNum + 1 >= totalPages - threshold && pgNum >= totalPages - threshold) {
                        return (
                            <li key={pgNum}>
                                <PaginatedLink base={base} pgNum={pgNum} currentPage={currentPage} />
                            </li>
                        );
                    }

                    return null;
                })}
                <li>
                    <Link className={cx("prevNext")} to={hasNextPage && `${base}${nextPage}`}>
                        <span className={cx("textLabel")}>Next</span> &rsaquo;
                    </Link>
                </li>
                <li>
                    <Link className={cx("lastPage")} to={hasNextPage && `${base}${totalPages}`}>
                        <span className={cx("textLabel")}>Last</span> &raquo;
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
