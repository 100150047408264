import React from "react";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";

import * as blogEntryStyles from "./singleBlogEntryItem.module.css";
import classNames from "classnames/bind";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";
import { singleBlogEntryItemTranslations } from "../../translations/blog";

// Used in both blogCategoryPage and blogLandingPage template pages
const SingleBlogEntryItem = ({
    title,
    slug,
    previewText,
    author,
    image,
    imageAltText,
    node_locale,
    metaFields,
    presentation = "card",
    widthVariation,
}) => {
    // CX binding method for styles
    const cx = classNames.bind(blogEntryStyles);
    const pageUrl = getLangPrefixedPath(`/blog/${slug}`, node_locale);

    // For fetching both the blog main image and meta image
    const mainImage = image ? image[0] : false;
    const metaImage = metaFields && metaFields.metaImage ? metaFields.metaImage[0] : false;

    let blogImage = mainImage || metaImage;

    if (slug === "qlik-talend-looking-forward" && ["card", "list"].includes(presentation)) {
        blogImage = {
            public_id: "blog/2023-images/whats-next-is-now-thumb-thumb_rsufho",
            width: 1080,
            height: 1080,
            format: "png",
        };
    }

    // presentation = card / list / featured
    const blogImageStyle = blogImage
        ? presentation === "featured"
            ? { width: "100%", height: "auto" }
            : {
                  width: "144px",
                  height: "144px",
                  objectFit: "cover",
                  objectPosition: "center",
              }
        : null;
    const blogImageAltText = imageAltText ?? "";

    // This function generates the author text
    const getAuthorText = () => {
        // The localised string for "By"
        const authorPrefix = `${singleBlogEntryItemTranslations[node_locale].authorTextPrefix} `;

        // No check if there are any authors, and also possibly iterate over multiple authors
        // If no authors, then the author is "Talend"
        if (!author) return <h5 className={cx("blogEntryAuthor")}>{`${authorPrefix} Talend`}</h5>;

        // 2 author = [author] & [author]
        // 2+ authors = [author], [author], ... & [author]
        return (
            <h5 className={cx("blogEntryAuthor")}>
                {authorPrefix}
                {author.map((a, i, arr) => (
                    <React.Fragment key={i}>
                        {a.name}
                        {i < arr.length - 1 ? (arr.length <= 2 ? " & " : i === 0 ? ", " : " & ") : ""}
                    </React.Fragment>
                ))}
            </h5>
        );
    };

    return (
        <Link to={pageUrl} className={cx("blogEntryItem", presentation, widthVariation)} newWindow={false}>
            {blogImage && (
                <div className={cx("blogEntryImageWrapper")}>
                    <Image
                        className={cx("blogEntryImage")}
                        image={blogImage}
                        style={blogImageStyle}
                        imgStyle={blogImageStyle}
                        alt={blogImageAltText}
                    />
                </div>
            )}
            <div className="blogEntryCopyWrapper">
                {presentation === "featured" ? (
                    <h2 className={cx("blogEntryTitle")}>{title}</h2>
                ) : (
                    <h3 className={cx("blogEntryTitle")}>{title}</h3>
                )}
                {getAuthorText()}
                <div className={cx("blogEntryDescription")}>{previewText}</div>
                <span className={cx("blogEntryReadMore")}>{singleBlogEntryItemTranslations[node_locale].readMore}</span>
            </div>
        </Link>
    );
};

export default SingleBlogEntryItem;
