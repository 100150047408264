// extracted by mini-css-extract-plugin
export var blogEntryAuthor = "singleBlogEntryItem-module--blogEntryAuthor--a92df";
export var blogEntryDescription = "singleBlogEntryItem-module--blogEntryDescription--0ba91";
export var blogEntryImageWrapper = "singleBlogEntryItem-module--blogEntryImageWrapper--e8fe6";
export var blogEntryItem = "singleBlogEntryItem-module--blogEntryItem--df69e";
export var blogEntryReadMore = "singleBlogEntryItem-module--blogEntryReadMore--61c7c";
export var blogEntryTitle = "singleBlogEntryItem-module--blogEntryTitle--4c514";
export var featured = "singleBlogEntryItem-module--featured--10bfa";
export var list = "singleBlogEntryItem-module--list--a5b13";
export var newsEntryReadMore = "singleBlogEntryItem-module--newsEntryReadMore--6a5eb";
export var w100 = "singleBlogEntryItem-module--w100--75297";
export var w50 = "singleBlogEntryItem-module--w50--a18c8";