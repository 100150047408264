// extracted by mini-css-extract-plugin
export var blueGradient = "HeroWithIcon-module--blueGradient--65f2a";
export var contentWrap = "HeroWithIcon-module--contentWrap--22ba5";
export var gridWrapper = "HeroWithIcon-module--gridWrapper--f86ac";
export var heroImage = "HeroWithIcon-module--heroImage--569cf";
export var heroImageWrapper = "HeroWithIcon-module--heroImageWrapper--7bfd1";
export var heroSection = "HeroWithIcon-module--heroSection--3b377";
export var purpleGradient = "HeroWithIcon-module--purpleGradient--3d933";
export var sectionDescription = "HeroWithIcon-module--sectionDescription--f6d26";
export var sectionHeading = "HeroWithIcon-module--sectionHeading--47312";
export var sectionHeadingIconWrapper = "HeroWithIcon-module--sectionHeadingIconWrapper--d5ea4";
export var vAlignBottom = "HeroWithIcon-module--vAlignBottom--c23f2";
export var vAlignTop = "HeroWithIcon-module--vAlignTop--291c7";