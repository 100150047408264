export const blogTranslations = {
    en: {
        heroHeading: "The data health blog",
        heroSubHeading: "Inspiration for your digital transformation",
        metaTitle: "The data health blog",
        metaDescription:
            "Insights on the latest and greatest in enterprise data, and expertise to help your organization deliver on the promise of healthy data.",
        seeMore: "See more",
    },
    "en-GB": {
        heroHeading: "The data health blog",
        heroSubHeading: "Inspiration for your digital transformation",
        metaTitle: "The data health blog",
        metaDescription:
            "Insights on the latest and greatest in enterprise data, and expertise to help your organization deliver on the promise of healthy data.",
        seeMore: "See more",
    },
    fr: {
        heroHeading: "Le blog data santé",
        heroSubHeading: "Inspiration pour votre transformation numérique",
        metaTitle: "Le blog data santé",
        metaDescription:
            "Des informations sur les données d'entreprise les plus récentes et les plus performantes et une expertise pour aider votre organisation à tenir la promesse de données saines.",
        seeMore: "Voir plus",
    },
    de: {
        heroHeading: "Der Datengesundheitsblog",
        heroSubHeading: "Inspiration für Ihre digitale Transformation",
        metaTitle: "Der Datengesundheitsblog",
        metaDescription:
            "Einblicke in die neuesten und besten Unternehmensdaten und Fachwissen, damit Ihr Unternehmen das Versprechen gesunder Daten einhalten kann.",
        seeMore: "Mehr sehen",
    },
    it: {
        heroHeading: "Il blog sulla salute dei dati",
        heroSubHeading: "Ispirazione per la tua trasformazione digitale",
        metaTitle: "Il blog sulla salute dei dati",
        metaDescription:
            "Approfondimenti sui dati aziendali più recenti e straordinari e competenze per aiutare la tua organizzazione a mantenere la promessa di dati sani.",
        seeMore: "Vedi altro",
    },
    ja: {
        heroHeading: "データヘルスブログ",
        heroSubHeading: "あなたのデジタル変革のためのインスピレーション",
        metaTitle: "データヘルスブログ",
        metaDescription:
            "最新かつ最高のエンタープライズデータに関する洞察、および組織が健全なデータの約束を実現するのに役立つ専門知識。",
        seeMore: "続きを見る",
    },
};
export const blogAuthorTranslations = {
    en: {
        moreByAuthor: "More by author",
    },
    fr: {
        moreByAuthor: "Plus par auteur",
    },
    de: {
        moreByAuthor: "Mehr vom autor",
    },
    it: {
        moreByAuthor: "Altro per autore",
    },
    ja: {
        moreByAuthor: "著者による詳細",
    },
};
export const singleBlogEntryItemTranslations = {
    en: {
        blogName: "Blog",
        authorTextPrefix: "By",
        readMore: "Read more",
        more: "More",
    },
    fr: {
        blogName: "Blog",
        authorTextPrefix: "Par",
        readMore: "Lire la suite",
        more: "Plus",
    },
    de: {
        blogName: "Blog",
        authorTextPrefix: "Von",
        readMore: "Weiterlesen",
        more: "Mehr",
    },
    it: {
        blogName: "Blog",
        authorTextPrefix: "Di",
        readMore: "Per saperne di più",
        more: "Più",
    },
    ja: {
        blogName: "ブログ",
        authorTextPrefix: "によって",
        readMore: "続きを読む",
        more: "もっと",
    },
};
