import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import React from "react";

// Styling
import * as styles from "./HeroWithIcon.module.css";
import classNames from "classnames/bind";
import formatClassName from "@v4/utils/formatClassName";
const cx = classNames.bind(styles);

export const HeroWithIcon = ({
    overline,
    title,
    description,
    gradientType,
    iconImage,
    iconImageStyle,
    iconImageAltText,
    heroImage,
    heroImageStyle,
    heroImageAltText,
    contentVerticalAlign,
}) => {
    // Add some vertical alignment classes here. Defaults to center.
    const vertAlignClass = formatClassName("vAlign", contentVerticalAlign || "Center");

    return (
        <section className={cx("heroSection", gradientType)}>
            <div className={cx("gridWrapper")}>
                <div className={cx("contentWrap", vertAlignClass)}>
                    {overline && <div className="sectionSmallHeading">{overline}</div>}
                    <div className={cx("sectionHeading")}>
                        {iconImage && (
                            <div className={cx("sectionHeadingIconWrapper")}>
                                <Image image={iconImage} imgStyle={iconImageStyle} alt={iconImageAltText} />
                            </div>
                        )}
                        <h1 className={cx("sectionTitle")}>{title}</h1>
                    </div>
                    <h3 className={cx("sectionDescription")}>{description}</h3>
                </div>
                {heroImage && (
                    <div className={cx("heroImageWrapper")}>
                        <Image
                            className={cx("heroImage")}
                            image={heroImage}
                            style={heroImageStyle}
                            alt={heroImageAltText}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};
