import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Pagination from "../../components/pagination/Pagination";
import { HeroWithIcon } from "../../components/hero/HeroWithIcon";
import SingleBlogEntryItem from "../../components/singleBlogEntryItem/SingleBlogEntryItem";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";

// Styling
import * as blogCategoryStyles from "./blogCategoryPage.module.css";
import classNames from "classnames/bind";
const cx = classNames.bind(blogCategoryStyles);

export const query = graphql`
    fragment Blog on ContentfulBlog {
        id
        title
        previewText
        featureLandingPage
        featureCategoryPage
        author {
            name
        }
        slug
        image {
            # fluid (maxWidth: 244)
            public_id
            width
            height
            format
        }
        imageAltText
        languages
        node_locale
        publishDate
        category {
            title
        }
        metaFields {
            metaTitle
            metaDescription
            metaImage {
                # fluid (maxWidth: 900, transformations: "c_fit")
                public_id
                width
                height
                format
            }
        }
    }
    query($categoryId: String!, $skip: Int = 0, $perPage: Int = 5, $locale: String = "en", $languages: [String]) {
        # Query for ALL blogs in the category (this is used for pagination)
        allBlogs: allContentfulBlog(
            filter: {
                node_locale: { eq: $locale }
                languages: { in: $languages }
                category: { elemMatch: { id: { eq: $categoryId } } }
            }
            sort: { fields: publishDate, order: DESC }
            limit: $perPage
            skip: $skip
        ) {
            totalCount
            nodes {
                ...Blog
            }
        }
        # Query for all non-featured blogs in the category
        nonFeaturedBlogs: allContentfulBlog(
            filter: {
                node_locale: { eq: $locale }
                languages: { in: $languages }
                category: { elemMatch: { id: { eq: $categoryId } } }
                featureCategoryPage: { nin: "Yes" }
            }
            sort: { fields: publishDate, order: DESC }
            limit: $perPage
            skip: $skip
        ) {
            totalCount
            nodes {
                ...Blog
            }
        }
        # Query only the featured blogs in the category (limit to 3)
        featuredBlogs: allContentfulBlog(
            filter: {
                node_locale: { eq: $locale }
                languages: { in: $languages }
                category: { elemMatch: { id: { eq: $categoryId } } }
                featureCategoryPage: { in: "Yes" }
            }
            sort: { fields: publishDate, order: DESC }
            limit: 3
        ) {
            totalCount
            nodes {
                ...Blog
            }
        }
        category: contentfulBlogCategory(id: { eq: $categoryId }) {
            id
            order
            title
            description
            slug
            icon {
                # fluid (maxWidth: 100, transformations: "c_fit")
                public_id
                width
                height
                format
            }
            iconWhite {
                # fluid (maxWidth: 100, transformations: "c_fit")
                public_id
                width
                height
                format
            }
            iconAltText
            image {
                # fluid (maxWidth: 774)
                public_id
                width
                height
                format
            }
            imageAltText
            metaFields {
                metaTitle
                metaDescription
                metaImage {
                    # fluid (maxWidth: 900, transformations: "c_fit")
                    public_id
                    width
                    height
                    format
                }
            }
            createdAt
            updatedAt
            node_locale
        }
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function BlogCategoryPage({ data, pageContext }) {
    const { locale } = pageContext;
    const category = data.category;

    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    const styleProps = {};

    // images.
    // get the hero image
    const heroImage = category.image ? category.image[0] : false;
    const heroImageStyle = heroImage
        ? {
              height: "auto",
          }
        : null;
    const heroImageAltText = category.imageAltText ?? "";

    // get the icon image
    const iconImage = category.iconWhite[0] || category.icon[0] || false;
    const iconImageStyle = category.icon
        ? {
              width: "48px",
              height: "auto",
          }
        : null;
    const iconImageAltText = category.iconAltText ?? "";

    // Set up breadcrumbs object
    let crumbList = {
        Blog: "/blog",
        [category.title]: null,
    };

    // The featured blogs section need to show exactly 3 blogs.
    // If there are less than 3 featured blogs, then show normal blogs until it adds up to 3.
    const featuredCount = 3;
    const tmpFeaturedBlogs = [...data.featuredBlogs.nodes];
    const newBlogs = [...data.nonFeaturedBlogs.nodes];
    let diff = featuredCount - tmpFeaturedBlogs.length;

    for (let step = 0; step < diff; step++) {
        tmpFeaturedBlogs.push(newBlogs[step]);
    }

    const newFeaturedBlogs = tmpFeaturedBlogs.filter((blog) => blog);

    // Adjust the layout if there really are less than 3 blog posts in total even.
    const variationClassName = newFeaturedBlogs.length === 2 ? "w50" : newFeaturedBlogs.length === 1 ? "w100" : "";

    const allBlogs = data.allBlogs.nodes;

    return (
        <Layout
            headerMenu={data.contentfulMenu}
            footerMenu={footerData}
            notificationBar={false}
            pageStyleProps={styleProps}
        >
            <SEO
                title={category.metaFields?.metaTitle ?? category.title}
                pathname={getLangPrefixedPath(`/blog/${category.slug}`, locale)}
                description={category.metaFields?.metaDescription ?? category.description}
                metaImage={category.metaFields?.metaImage ?? null}
                modifiedTime={category.updatedAt}
                createdTime={category.createdAt}
                languages={["English"]}
                type={`BlogPost`}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <HeroWithIcon
                overline={"Blog"}
                title={category.title}
                gradientType={"bgGradientBlogGradient"}
                description={category.description}
                heroImage={heroImage}
                heroImageStyle={heroImageStyle}
                heroImageAltText={heroImageAltText}
                iconImage={iconImage}
                iconImageStyle={iconImageStyle}
                iconImageAltText={iconImageAltText}
            />
            <section className={cx("container")}>
                <Breadcrumbs crumbList={crumbList} />
                <div className={cx("featuredWrapper")}>
                    {newFeaturedBlogs.map(
                        (blog) =>
                            blog && (
                                <SingleBlogEntryItem
                                    {...blog}
                                    key={blog.id}
                                    presentation="featured"
                                    widthVariation={variationClassName}
                                />
                            )
                    )}
                </div>
                <div className={cx("listingWrapper")}>
                    {allBlogs.map(
                        (blog) =>
                            blog && (
                                <SingleBlogEntryItem
                                    {...blog}
                                    key={blog.id}
                                    presentation="list"
                                    widthVariation={variationClassName}
                                />
                            )
                    )}
                </div>
                <Pagination
                    perPage={pageContext.perPage}
                    totalCount={pageContext.totalCount}
                    currentPage={pageContext.currentPage + 1}
                    skip={pageContext.skip}
                    base={pageContext.base}
                />
            </section>
        </Layout>
    );
}
